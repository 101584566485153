const ReviewData = [
    {
        id: 12,
        name: "Jody Ward Houle",
        comment:
         "Thank you!!! I had the most wonderful day with you! It was exactly what I love to do.  The Palace was beautiful and I loved the pace of this tour.  Thank you for sharing your extensive knowledge with me and most of all for the great company.  You have made my time in Portugal so memorable!",
        photoMobile: "img/jody60.png",
        photoDesktop: "img/jody120.png"
    },
    {
        id: 11,
        name: "Stijlgenoten",
        comment:
         "25 jaar Stijlgenoten! Dat ging niet onopgemerkt voorbij! Met z'n allen naar Lissabon! Wat een prachtstad vol cultuur, architectuur, natuur, heerlijk eten en heel veel kleur! Genoten van een mooi afwisselend programma georganiseerd door @just.lisbon Renate Vicente! Nederlands sprekend maar al ruim 30 jaar wonend in dit mooi stukje Portugal! Zij weet je echt alles te vertellen!",
        photoMobile: "img/stijlgenoten60.png",
        photoDesktop: "img/stijlgenoten120.png"
    },
    {
        id: 10,
        name: "Anke & Vriendinnen",
        comment:
            "Dé aanrader om je stedentrip Lissabon mee te beginnen! Renate is Nederlandse maar is inmiddels ook een trotse Portugese die je het beste van Lissabon gunt en laat ervaren. Gedurende de Bohemian route bleef Renate ons boeien met haar kennis van de geschiedenis, cultuur en bezienswaardigheden van Lissabon. Renate, heel veel dank voor deze bijzonder mooie en leuke start van ons verblijf!",
        photoMobile: "img/anke60.png",
        photoDesktop: "img/anke120.png"
    },
    {
        id: 9,
        name: "Fam. Weijers",
        comment:
            "Renate heel erg bedankt voor de super leuke en leerzame ochtend! Wij hebben er van genoten en mocht ik mensen weten die een gids zoeken, dan zal ik je kaartje mee delen met ze",
        photoMobile: "img/weijers60.png",
        photoDesktop: "img/weijers120.png"
    },
    {
        id: 8,
        name: "Cindy",
        comment:
            "Bedankt voor de leuke tour en alle info! Groetjes Mike en Cindy",
        photoMobile: "img/cindy60.png",
        photoDesktop: "img/cindy120.png"
    },
    {
        id: 7,
        name: "Fenny, Ineke, José en Ingrid",
        comment:
            "Een dag op stap geweest met just.lisbon (bohemiantour). Renate kon veel interessants vertellen en had werkelijk overal een antwoord op. Ze liet ons plekken zien die we anders nooit ontdekt zouden hebben.  Een aanrader dus!",
        photoMobile: "img/fenne60.png",
        photoDesktop: "img/fenne120.png"
    },
    {
        id: 6,
        name: "Fam. Leenhouts",
        comment:
            "We hadden al verschillende goede tips gehad voor ons bezoek aan Lissabon, maar veruit de beste tip was te beginnen met een rondleiding, verzorgd door jou.  Je hebt ons van het begin tot het eind geboeid. Niet alleen door je geweldige kennis van de stad en het land, maar ook door je persoonlijke betrokkenheid. We hebben je ervaren als een aimabele, deskundige gids. We raden iedereen, die een bezoek aan Lissabon wil maken van harte aan die trip te starten met jou!",
        photoMobile: "img/leenhouts60.png",
        photoDesktop: "img/leenhouts120.png"
    },
    {
        id: 1,
        name: "Reg Heesters",
        comment:
            "Na een bijzondere leuke vakantieweek in Lissabon weer terug in Nederland. Terugkijkend een goede keuze geweest om de stad te leren kennen onder jouw bezielende (rond)leiding. Goed gedocumenteerd, tijd voor vraag en antwoord nemend, persoonlijke aandacht en adviserend naar juiste die kleine details. Wat Lissabon heeft geleerd is dat achter elke gevel een pareltje kan schuilen. Je moet het maar weten, zoals de bakkerij, het klooster/bierbrouwerij, het literaire koffiehuis enz. Nogmaals dank en succes met je onderneming.",
        photoMobile: "img/reg60.png",
        photoDesktop: "img/reg120.png"
    },
    {
        id: 2,
        name: "Anita Barmentloo-Leerkes",
        comment:
            "Wij hebben 2 prachtige wandelingen met jou gedaan. Eén door de wijk Alfama en één naar de ruine in Sintra (aanrader!) Wat wist jij veel te vertellen en wat was het gezellig. Onderweg gezellig samen iets drinken en bij de ruine een lekker broodje gegeten.",
        photoMobile: "img/1stCommentAvatarMobile.png",
        photoDesktop: "img/1stCommentAvatar.png"
    },
    {
        id: 3,
        name: "Mariëlle Boers",
        comment:
            "Heerlijke dag gehad in Lissabon. Met dank. Je visite kaartje laten we hier achter in de toeristische gastenmap bij onze vrienden. 👌🏼",
        photoMobile: "img/remco60.png",
        photoDesktop: "img/remco120.png"
    },
    {
        id: 4,
        name: "Fam. Keijzers",
        comment:
            "In augustus heeft Renate ons rondgeleid in Lissabon en Sintra. Renate heeft veel kennis en het is relaxed om in het Nederlands al die informatie te krijgen. Naast alle informatie is twee dagen op pad met Renate ook erg gezellig. Ze weet de weg en spreekt de taal. Een absolute aanrader!",
        photoMobile: "img/keizjers60.png",
        photoDesktop: "img/keizjers120.png"
    },
    {
        id: 5,
        name: "Studenten Journalistiek",
        comment:
            "Renate heeft ons een uitgebreide en mooie stadswandeling door Alfama gegeven. Onze groep bestond uit 30 studenten Journalistiek en iedereen was heel enthousiast. De wandeling had een goede balans tussen lopen, kijken en luisteren. Renate heeft ons zelfs in de dagen erna nog geholpen met restaurants en ons andere tips gegeven. Absoluut een aanrader en Renate bedankt!",
        photoMobile: "img/studenten60.png",
        photoDesktop: "img/studenten120.png"
    }
];

export default ReviewData;
