import React, { useContext } from 'react'
import { AppContext } from "../../context"
import styled from "styled-components"
import mae from "../../mae.png"
import maeGrande from "../../maeGrande.png"
import { Spring } from "react-spring/renderprops"
import { Helmet } from "react-helmet"

const About = () => {
    const value = useContext(AppContext)

    return (
        <>
            <Helmet>
                <title>Over Just Lisbon - Nederlandse Gids Lissabon</title>

                <meta name="description" content="Een Nederlandse gids, die op enthousiaste wijze stadswandelingen organiseert door Lissabon. Ga aan de wandel met Just Lisbon" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content={value.state.metaData.author} />
                <meta name="keywords" content={value.state.metaData.keywords} />

                <meta property="og:title" content="Over Just Lisbon - - Nederlandse Gids Lissabon" />
                <meta property="og:description" content="Een Nederlandse gids, die op enthousiaste wijze stadswandelingen organiseert door Lissabon. Ga aan de wandel met Just Lisbon" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={value.state.metaData.url} />
            </Helmet>
            <Spring
            from={{ opacity: 0}}
            to={{ opacity: 1 }}
            config={{ duration: 600 }}
            >
                {props => <AboutWrapper className="about" style={props}>
                    <Header style={props} className="about-header">
                        <h2>Over Just Lisbon</h2>
                    </Header>
                    <SubHeaderYears style={props} className="about-subheader">
                        <h3>Just Lisbon door de jaren heen</h3>
                    </SubHeaderYears>
                    <TextYears style={props} className="about-text">
                        <p>
                            Na ruim 20 jaar werkzaam geweest te zijn voor diverse Nederlandse reisorganisaties leek mij in
                            2014 de tijd rijp om toeristen op mijn manier te laten genieten van het prachtige Lissabon.
                            <br/><br/>
                            Ik ben toen begonnen met het uitstippelen van diverse stadswandelingen, waarbij niet alleen de
                            rijke geschiedenis van Lissabon aan bod komt, maar ook de Portugese keuken, haar bijzondere
                            flora en het huidige Portugese leven.
                            <br/><br/>
                            Mijn enthousiasme haal ik enerzijds uit het feit, dat ik een echte Portugal lover ben en anderzijds
                            uit het feit dat ik op deze manier allerhande mensen ontmoet. Of u nu met z’n tweetjes reist, een
                            gezin bent met opgroeiende kinderen of deel uit maakt van een bedrijfsuitje, met veel plezier ga ik
                            met u aan de wandel.
                            <br/><br/>
                            Mijn vaste klanten zijn de busgroepen van de Oad, diverse Nederlandse Middelbare Scholen en
                            de kampeerreizen van de ANWB. In 2016 had ik zelfs een klein ‘optreden’ in het TV-programma
                            We zijn er bijna. Wat een leuke ervaring was dat.
                            <br/><br/>
                            De afgelopen 6 jaar heb ik mijn stadswandelingen in Lissabon uitgebreid met een fietstoer en een 2-tal
                            wandelingen in het nabij gelegen Sintra, de vroegere zomerresidentie van de koninklijke familie.
                            Een bijzonder plekje met een rijke geschiedenis en een prachtige natuur.
                            Zoals u ziet, bij Just Lisbon bent u aan het goede adres!
                        </p>
                    </TextYears>
                    <SubHeaderGuide style={props} className="about-subheader">
                        <h3>De Gids</h3>
                    </SubHeaderGuide>
                    <Avatar className="about-avatar">
                        {window.innerWidth < 800 ? <img src={mae} alt="Renate Vicente"></img> : <img src={maeGrande} alt="Renate Vicente"></img>} 
                    </Avatar>
                    <Name className="about-name">
                        <p>Renate Vicente</p>
                    </Name>
                    <Description className="about-description">
                        <h3>
                            Professionele Nederlandstalige gids met 30 jaar kennis van de Portugese Cultuur.
                            <br/><br/>
                            Geregistreerd bij het Turismo de Portugal onder nummer RNAAT 259/2022.
                        </h3>
                    </Description>
                    <Line className="about-line">
                        <hr/>
                    </Line>
                    <FollowHeader className="about-follow-header">
                        <h2>Neem Contact op</h2>
                    </FollowHeader>
                    <Icons className="about-icons">
                        <a href="mailto:renatevicente@gmail.com">
                            <i className="far fa-envelope"></i>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="https://www.instagram.com/just.lisbon/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </Icons>
                </AboutWrapper>}
            </Spring>
        </>
    )
}

export default About

const AboutWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 70px 70px auto 70px 1fr 40px auto 2px 50px 50px;
    grid-template-areas:
        "header header"
        "sub-header-years sub-header-years"
        "text-years text-years"
        "sub-header-guide sub-header-guide"
        "avatar avatar"
        "name name"
        "description description"
        "line line"
        "follow-header follow-header"
        "icons icons";
`

const Header = styled.div`
    grid-area: header;
    text-align: center;
    
    h2 {
        font-weight: 800;
    }
`

const SubHeaderYears = styled(Header)`
    grid-area: sub-header-years;
    text-align: left;
    
    h3 {
        margin-left: 30px;
        font-weight: 700;
    }
`

const TextYears = styled.div`
    grid-area: text-years;
    text-align: left;
    margin-left: 30px;
`

const SubHeaderGuide = styled(SubHeaderYears)`
    grid-area: sub-header-guide;
`

const Avatar = styled.div`
    grid-area: avatar;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 200px;
    }
`

const Name = styled.div`
    grid-area: name;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
`

const Description = styled.div`
    grid-area: description;
    text-align: center;

    h3 {
        font-weight: 400;
    }
`

const Line = styled.div`
    grid-area: line;
    
    hr {
        color: var(--mainWhite);
        max-width: 300px;
    }
`

const FollowHeader = styled.div`
    grid-area: follow-header;
    text-align: center;
`

const Icons = styled.div`
    grid-area: icons;
    text-align: center;
    font-size: 30px;

    a {
        color: var(--mainWhite);
    }

    i:hover {
        filter: drop-shadow(0px 0px 2px var(--mainWhite));
    }
`
