import React from "react";
import "./App.css";
import Navbar from "./components/sections/Navbar";
import Home from "./components/pages/Home";
import Tours from "./components/pages/Tours";
import Book from "./components/pages/Book";
import About from "./components/pages/About";
import Tour from "./components/pages/Tour";
import { Switch, Route } from "react-router-dom";
import { AppProvider } from "./context";
import Footer from "./components/sections/Footer";

function App() {
    return (
        <AppProvider>
            <Navbar />
            <Switch>
                <Route exact={true} path="/tours" component={Tours} />
                <Route exact={true} path="/book" component={Book} />
                <Route exact={true} path="/about" component={About} />
                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/:handle" component={Tour} />
            </Switch>
            <Footer />
        </AppProvider>
    );
}

export default App;
