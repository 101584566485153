import React, { useState } from "react";
import { Spring, animated as a, config } from "react-spring/renderprops";
import styled from "styled-components";

const AnimatedButton = (props) => {
  const [pressed, setPressed] = useState(false);
  return (
    <Spring
      native
      from={{ scale: 1 }}
      to={{ scale: pressed ? 1.1 : 1 }}
      config={config.wobbly}
    >
      {({ scale }) => (
        <Button
          style={{
            transform: scale.interpolate((scale) => `scale(${scale})`),
          }}
          onMouseEnter={() => setPressed(true)}
          onClick={() => setPressed(false)}
          onMouseLeave={() => setPressed(false)}
          color={props.color}
          bgcolor={props.bgcolor}
          fontsize={props.fontsize}
        >
          {props.icon ? <i className="fas fa-shopping-basket"></i> : null}
          {" " + props.text}
        </Button>
      )}
    </Spring>
  );
};

export default AnimatedButton;

const Button = styled(a.button)`
  cursor: pointer;
  border: 1px solid ${(props) => props.bgcolor};
  border-radius: 30px;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontsize};
  font-family: "Montserrat";
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 35px;
  padding-left: 35px;
  transition: filter ease-in;

  &:hover {
    filter: drop-shadow(0px 0px 5px var(--mainWhite));
  }
`;
