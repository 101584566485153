import React, { useContext } from "react"
import { AppContext } from "../context"
import TourCard from "./TourCard"

const TourCards = (props) => {
    const value = useContext(AppContext)

    return (
        value.getTourCards(props.location).map(item => {
            return <TourCard key={item.id} photoMobile={item.photoMobile} photoDesktop={item.photoDesktop} name={item.name}></TourCard>
        })
    )
}

export default TourCards
