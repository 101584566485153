import React from 'react'

function useFormValidation(initialState, validate) {
    const [values, setValues] = React.useState(initialState)
    const [errors, setErrors] = React.useState({})
    const [isSubmitting, setSubmitting] = React.useState(false)

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

   React.useEffect(() => {
       if (isSubmitting) {
           const noErrors = Object.keys(errors).length === 0
           if (noErrors) {
                fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({ "form-name": "booking", ...values })
                })
                    .then(() => alert("Success!"))
                    .catch(error => alert(error));
               setSubmitting(false)
           } else {
               setSubmitting(false)
           }
       }
   }, [errors, isSubmitting, values])
   
   function handleChange(event) {
       setValues({
           ...values,
           [event.target.name]: event.target.value
       })
   }

   function handleBlur() {
        const validationErrors = validate(values)
        setErrors(validationErrors)
   }

   function handleSubmit(event) {
        event.preventDefault();
        const validationErrors = validate(values)
        setErrors(validationErrors)
        setSubmitting(true)
    }

   return { handleChange, values, handleSubmit, handleBlur, errors, isSubmitting }
}

export default useFormValidation
