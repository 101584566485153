import React from "react";
import AnimatedImage from "./AnimatedImage";
import { Link } from "react-router-dom";
import styled from "styled-components";

const TourCard = props => {
    const image =
        window.innerWidth < 800 ? props.photoMobile : props.photoDesktop;
    const lowered = props.name.toLowerCase();
    const slug = lowered.replace(/[ ]/g, "-");

    return (
        <NewLink
            innerwidth={window.innerWidth}
            style={{ textDecoration: "none" }}
            to={{
                pathname: slug,
                state: {
                    tourName: props.name
                }
            }}
            name={props.name}
        >
            <AnimatedImage image={image} name={props.name}></AnimatedImage>
        </NewLink>
    );
};

export default TourCard;

const NewLink = styled(Link)`
    img {
        margin-top: ${props => (props.name === "De Trip-voor-een-tip Wandeling" && props.innerwidth >= 800 ? "-3px" : "0px")};;
        width: ${props => (props.innerwidth < 800 ? "150px" : "auto")};
        height: ${props => (props.innerwidth < 800 ? "170px" : "auto")};

    }
`;
