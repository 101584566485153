import React, { useContext } from "react";
import { AppContext } from "../../context";
import styled from "styled-components";
import { Spring } from "react-spring/renderprops";
import { Helmet } from "react-helmet";

import useFormValidation from "../form-logic/useFormValidation";
import validateAuth from "../form-logic/validateAuth";

const Book = (props) => {
  let currentTour = props.location.state
    ? props.location.state.tourName
    : "volksbuurten";

  const INITIAL_STATE = {
    name: "",
    email: "",
    number: "",
    tour: currentTour,
    amount: "",
  };
  const value = useContext(AppContext);
  const {
    handleChange,
    values,
    handleSubmit,
    handleBlur,
    errors,
    isSubmitting,
  } = useFormValidation(INITIAL_STATE, validateAuth);

  return (
    <>
      <Helmet>
        <title>Boek Een Toer - Nederlandse Gids Lissabon</title>

        <meta
          name="description"
          content="Boek een stadswandeling in Lissabon"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content={value.state.metaData.author} />
        <meta name="keywords" content={value.state.metaData.keywords} />

        <meta
          property="og:title"
          content="Boek Een Toer - - Nederlandse Gids Lissabon"
        />
        <meta
          property="og:description"
          content="Boek een stadswandeling in Lissabon"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={value.state.metaData.url} />
      </Helmet>
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 600 }}
      >
        {(props) => (
          <BookWrapper className="book" style={props}>
            <Header className="book-header">Boek Een Toer</Header>
            <Description className="book-description">
              <p>
                Klaar voor Portugal? Je bent één stap verwijderd van jouw droom
                toer, vul het formulier in met de benodigde informatie.
              </p>
            </Description>
            <Line className="book-line">
              <hr />
            </Line>
            <form onSubmit={handleSubmit}>
              <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                config={{ duration: 300 }}
              >
                {(styledProps) => (
                  <FormWrapper className="book-form" style={styledProps}>
                    <Name>
                      <p>
                        <i className="fas fa-user"></i> Naam *
                      </p>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        name="name"
                        autoComplete="off"
                        placeholder="Uw naam"
                        errors={errors.name}
                      />
                      {errors.name && <ErrorText>{errors.name}</ErrorText>}
                    </Name>
                    <Email>
                      <p>
                        <i className="fas fa-at"></i> Email Adres *
                      </p>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                        autoComplete="off"
                        placeholder="Uw email adres"
                        errors={errors.email}
                      />
                      {errors.email && <ErrorText>{errors.email}</ErrorText>}
                    </Email>
                    <Number>
                      <p>
                        <i className="fas fa-phone"></i> Contact *
                      </p>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.number}
                        name="number"
                        placeholder="Uw contact"
                        errors={errors.number}
                      />
                      {errors.number && <ErrorText>{errors.number}</ErrorText>}
                    </Number>
                    <Tour>
                      <p>
                        <i className="far fa-map"></i> Wandeling *
                      </p>
                      <select
                        value={values.tour}
                        name="tour"
                        onChange={handleChange}
                      >
                        {value.state.toursData
                          .filter((tour) => tour.toBook)
                          .map((tour) => {
                            return (
                              <option key={tour.id} value={tour.bookName}>
                                {tour.bookName}
                              </option>
                            );
                          })}
                      </select>
                    </Tour>
                    <Amount>
                      <p>
                        <i className="fas fa-users"></i> Aantal pers. *
                      </p>
                      <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.amount}
                        name="amount"
                        placeholder="Aantal"
                        errors={errors.amount}
                      />
                      {errors.amount && <ErrorText>{errors.amount}</ErrorText>}
                    </Amount>
                    <Comment className="book-comment">
                      <p>
                        <i className="fas fa-comment"></i> Commentaar
                      </p>
                      <textarea
                        name="comment"
                        placeholder="Uw commentaar"
                        value={values.comment}
                        onChange={handleChange}
                      />
                    </Comment>
                    <Submit>
                      <button disabled={isSubmitting} type="submit">
                        BOEK
                      </button>
                    </Submit>
                  </FormWrapper>
                )}
              </Spring>
            </form>
          </BookWrapper>
        )}
      </Spring>
    </>
  );
};

export default Book;

const BookWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 60px 2px 1fr;
  grid-template-areas:
    "book-header"
    "book-description"
    "book-line"
    "book-form";
`;

const Header = styled.h2`
  grid-area: book-header;
  text-align: center;
`;

const Description = styled.div`
  grid-area: book-description;
  text-align: center;
`;

const Line = styled.div`
  grid-area: book-line;

  hr {
    color: var(--mainWhite);
    max-width: 300px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-weight: 700;
`;

const Input = styled.input`
  background-color: transparent;
  color: var(--mainWhite);
  font-family: "Montserrat";
  font-size: 19px;
  height: 30px;
  border: ${(props) => (props.errors ? "1px solid red" : "none")};
  border-bottom: ${(props) =>
    props.errors ? "1px solid red" : "1px solid var(--mainWhite)"};

  &:focus {
    outline: 0;
  }
`;

const FormWrapper = styled.div`
  grid-area: book-form;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, auto);
  grid-template-areas:
    "name"
    "email"
    "number"
    "tour"
    "amount"
    "comment"
    "submit";
`;

const Email = styled.div`
  margin-right: auto;
  margin-left: auto;
  grid-area: email;
  width: 255px;

  p {
    font-size: 19px;
    font-weight: 500;

    i {
      font-size: 17px;
    }
  }
`;

const Name = styled(Email)`
  grid-area: name;
`;

const Number = styled(Email)`
  grid-area: number;
`;

const Amount = styled(Email)`
  grid-area: amount;
`;

const Tour = styled(Email)`
  grid-area: tour;

  select {
    font-size: 17px;
    font-family: "Montserrat";
    text-transform: capitalize;
    background-color: var(--mainBlack);
    color: var(--mainWhite);
    height: 30px;

    &:focus {
      outline: 0;
    }
  }
`;

const Submit = styled.div`
  grid-area: submit;
  margin-right: auto;
  margin-left: auto;

  button {
    cursor: pointer;
    margin-top: 20px;
    border: 1px solid var(--mainWhite);
    border-radius: 20px;
    background-color: var(--mainWhite);
    color: var(--mainBlack);
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 40px;
    padding-left: 40px;

    &:hover {
      filter: drop-shadow(0px 0px 3px var(--mainWhite));
    }
  }
`;

const Comment = styled(Email)`
  grid-area: comment;

  textarea {
    background-color: transparent;
    color: var(--mainWhite);
    font-family: "Montserrat";
    font-size: 19px;
  }
`;
