import React, { Component } from "react";
import ToursData from "./ToursData";
import ReviewData from "./ReviewData";
import MetaData from "./MetaData";

const AppContext = React.createContext();

class AppProvider extends Component {
    state = {
        toursData: ToursData,
        reviewData: ReviewData,
        metaData: MetaData,
        isOpen: false
    };

    getTourCards = (location) => {
        return this.state.toursData.filter(tour => tour.location === location)
    }

    getTour = (tourName) => {
        return this.state.toursData.find(tour => tour.name ===  tourName)
    }

    toogleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    state: this.state,
                    getTourCards: this.getTourCards,
                    getTour: this.getTour,
                    toggleMenu: this.toogleMenu
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

const AppConsumer = AppContext.Consumer;

export { AppProvider, AppConsumer, AppContext };