import React, { useContext } from "react";
import { AppContext } from "../../context";
import styled from "styled-components";
import photo from "../../main-photo.jpg";
import photoBig from "../../main-photo-big.png";
import ReviewList from "../ReviewList";
import { Link } from "react-router-dom";
import AnimatedButton from "../AnimatedButton";
import { Spring } from "react-spring/renderprops";
import { Helmet } from "react-helmet";

const Home = () => {
  const value = useContext(AppContext);

  return (
    <>
      <Helmet>
        <title>Just Lisbon - Nederlandse Gids Lissabon</title>

        <meta
          name="description"
          content="Lissabon ontdekken met een lokale Nederlandse gids"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content={value.state.metaData.author} />
        <meta name="keywords" content={value.state.metaData.keywords} />

        <meta
          property="og:title"
          content="Just Lisbon - Nederlandse Gids Lissabon"
        />
        <meta
          property="og:description"
          content="Lissabon ontdekken met een lokale Nederlandse gids"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={value.state.metaData.url} />
      </Helmet>
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 600 }}
      >
        {(props) => (
          <HomeWrapper className="home" style={props}>
            <MainHeader className="home-main-header">
              <h1>Wilt u Portugal op een leuke manier ontdekken?</h1>
            </MainHeader>
            <SubHeader className="home-sub-header">
              <h2>Kies uw vakantie ervaring.</h2>
            </SubHeader>
            <Click className="home-click">
              <Link to="/tours">
                <AnimatedButton
                  text="TOON MIJ ALLE TOERS"
                  color="var(--mainBlack)"
                  bgcolor="var(--mainWhite)"
                  icon={false}
                  fontsize="20px"
                ></AnimatedButton>
              </Link>
            </Click>
            <MainPhoto className="home-photo">
              <img
                src={window.innerWidth < 800 ? photo : photoBig}
                alt="Ponte 25 de Abril"
              ></img>
            </MainPhoto>
            <Line className="home-line">
              <hr />
            </Line>
            <Added>
              <ReviewList />
            </Added>
          </HomeWrapper>
        )}
      </Spring>
    </>
  );
};

export default Home;

const HomeWrapper = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 10px 1fr 10px;
  grid-template-rows: 110px 30px 110px 1fr 20px auto;
  grid-template-areas:
    ". main ."
    ". sub ."
    ". button ."
    ". photo ."
    ". line ."
    ". review .";
`;

const MainHeader = styled.div`
  grid-area: main;
  font-family: "EB Garamond", serif;
  h1 {
    font-size: 30px;
    font-weight: 800;
  }
`;

const SubHeader = styled.div`
  grid-area: sub;

  h2 {
    margin-top: 0%;
    font-weight: 500;
    font-size: 130%;
  }
`;

const Click = styled.div`
  grid-area: button;

  button {
    margin-top: 20px;
  }
`;

const MainPhoto = styled.div`
  grid-area: photo;

  button {
    position: absolute;
    top: 70%;
    left: 8%;
    margin-top: 20px;
    border: 1px solid var(--mainBlack);
    border-radius: 30px;
    background-color: var(--mainBlack);
    color: var(--mainWhite);
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 35px;
    padding-left: 35px;
  }
`;

const Line = styled.div`
  grid-area: line;

  hr {
    color: var(--mainWhite);
    max-width: 400px;
  }
`;

const Added = styled.div`
  grid-area: review;
`;
