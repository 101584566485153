import React, { useContext } from "react";
import { AppContext } from "../../context";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Link } from "react-router-dom";
import AnimatedButton from "../AnimatedButton";
import { Helmet } from "react-helmet";

const Tour = (props) => {
  const value = useContext(AppContext);
  const tour = value.getTour(props.location.state.tourName);

  return (
    <>
      <Helmet>
        <title>{tour.name} - Nederlandse Gids Lissabon</title>

        <meta name="description" content={tour.description} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content={value.state.metaData.author} />
        <meta name="keywords" content={value.state.metaData.keywords} />

        <meta property="og:title" content={tour.name} />
        <meta property="og:description" content={tour.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={value.state.metaData.url} />
      </Helmet>
      <TourWrapper className="tour">
        <Name className="tour-header">
          <h2>{tour.name}</h2>
        </Name>
        <Description className="tour-info">
          <h2>
            <i className="fas fa-info-circle"></i> Beschrijving
          </h2>
          <p>{tour.description}</p>
        </Description>
        <Info className="tour-info">
          <h2>
            <i className="fas fa-check"></i> Inclusief
          </h2>
          <ul>
            {tour.included.map((item) => {
              return (
                <li key={item}>
                  <p>{item}</p>
                </li>
              );
            })}
          </ul>
        </Info>
        <Price className="tour-info">
          <h2>
            <i className="fas fa-euro-sign"></i> Prijs
          </h2>
          <p>{tour.price}</p>
          {tour.bookName !== "walkingdinner" && (
            <p style={{ fontStyle: "italic" }}>
              Groepsprijs vanaf 10 pers. op aanvraag.
            </p>
          )}
          <Link
            to={{
              pathname:
                tour.bookName !== "walkingdinner"
                  ? "/book"
                  : "https://www.walkingdinner.com/justlisbon/lissabon",
              state: {
                tourName: tour.bookName,
              },
            }}
            target={tour.bookName !== "walkingdinner" ? null : "_blank"}
            rel={
              tour.bookName !== "walkingdinner" ? null : "noopener noreferrer"
            }
            style={{ textDecoration: "none" }}
          >
            <AnimatedButton
              text={
                tour.bookName !== "walkingdinner"
                  ? "BOEK NU"
                  : "BOEK NU OP WALKING DINNER"
              }
              icon={true}
              color="var(--mainBlack)"
              bgcolor="var(--mainWhite)"
              fontsize={tour.bookName !== "walkingdinner" ? "20px" : "13px"}
            ></AnimatedButton>
          </Link>
        </Price>
        <Gallery className="tour-gallery">
          <ImageGallery
            showBullets={true}
            showPlayButton={false}
            showFullscreenButton={false}
            items={tour.images}
          />
        </Gallery>
      </TourWrapper>
    </>
  );
};

export default Tour;

const TourWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 10px 1fr 10px;
  grid-template-rows: 70px 1fr auto 250px 400px;
  grid-template-areas:
    ". name ."
    ". description ."
    ". info ."
    ". price ."
    ". gallery .";
`;

const Name = styled.div`
  grid-area: name;

  h2 {
    text-align: center;
  }
`;

const Description = styled.div`
  grid-area: description;

  i {
    font-size: 18px;
  }

  h2 {
    font-size: 20px;
    position: relative;

    &::after {
      background-color: #efefef;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      width: 150px;
    }
  }

  p {
    white-space: pre-line;
    margin-top: -10px;
  }

  ul {
    margin-left: -18px;
  }
`;

const Info = styled(Description)`
  grid-area: info;
`;

const Price = styled(Description)`
  grid-area: price;
  white-space: pre-line;

  button {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
  }
`;

const Gallery = styled.div`
  grid-area: gallery;
`;
