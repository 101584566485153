import React, { useContext } from 'react'
import { AppContext } from "../../context"
import styled from "styled-components"
import { Spring } from "react-spring/renderprops"
import TourCards from "../TourCards"
import { Helmet } from "react-helmet"

const Tours = () => {
    const value = useContext(AppContext)

    return (
        <>
            <Helmet>
                <title>Wandel Keuzes - Nederlandse Gids Lissabon</title>

                <meta name="description" content="Unieke gezellige stadswandelingen in Lissabon en Sintra, met aandacht voor de Portugese geschiedenis, cultuur, gastronomie en het huidige leven" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content={value.state.metaData.author} />
                <meta name="keywords" content={value.state.metaData.keywords} />

                <meta property="og:title" content="Wandel Keuzes - - Nederlandse Gids Lissabon" />
                <meta property="og:description" content="Unieke gezellige stadswandelingen in Lissabon en Sintra, met aandacht voor de Portugese geschiedenis, cultuur, gastronomie en het huidige leven" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={value.state.metaData.url} />
            </Helmet>
            <ToursWrapper className="tours">
                <Header>
                    <h2 className="tours-header">Wandel Keuzes</h2>
                </Header>
                <LisbonHeader>
                    <p className="tours-subheader">Lissabon</p>
                </LisbonHeader>
                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    config={{ duration: 300 }}
                >
                    {lisbonProps => (
                        <Lisbon style={lisbonProps} className="tours-lisbon">
                            <TourCards location="lisbon"></TourCards>
                        </Lisbon>
                    )}
                </Spring>
                <SintraHeader>
                    <p className="tours-subheader">Sintra</p>
                </SintraHeader>
                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    config={{ duration: 300 }}
                >
                    {sintraProps => (
                        <Sintra style={sintraProps} className="tours-sintra">
                            <TourCards location="sintra"></TourCards>
                        </Sintra>
                    )}
                </Spring>
            </ToursWrapper>
        </>
    )
}

export default Tours

const ToursWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 50px 1fr 50px 1fr;
    grid-template-areas:
        "header"
        "lisbon-header"
        "lisbon"
        "sintra-header"
        "sintra";
`

const Header = styled.div`
    grid-area: header;
    text-align: center;
`

const LisbonHeader = styled.div`
    grid-area: lisbon-header;
    font-weight: 500;
    font-size: 20px;

    p {
        margin-left: 20px;
    }
`

const SintraHeader = styled(LisbonHeader)`
    grid-area: sintra-header;
`

const Lisbon = styled.div`
    grid-area: lisbon;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: 170px;

    a {
        margin-left: auto;
        margin-right: auto;
    }
`

const Sintra = styled(Lisbon)`
    grid-area: sintra;
`
