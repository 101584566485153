import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <FooterWrap className="footer">
      <Copyright className="footer-copyright">
        © 2024 - Lucas Vicente | RNAAT 259/2022
      </Copyright>
    </FooterWrap>
  );
};

export default Footer;

const FooterWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". copyright .";
`;

const Copyright = styled.h3`
  grid-area: copyright;
  text-align: center;
`;
