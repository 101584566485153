import React, { useContext } from "react"
import { AppContext } from "../../context"
import styled from "styled-components"
import { Link } from "react-router-dom"
import logo from "../../logo.png"
import NavbarLinks from "./NavbarLinks"

const Navbar = () => {
    const value = useContext(AppContext)

    return (
        <NavbarWrapper className="navbar" isOpen={value.state.isOpen}>
            <NavbarLogo className="navbar-logo">
                <Link to="/">
                    <img src={logo} alt="Just Lisbon"></img>
                </Link>
            </NavbarLogo>
            {window.innerWidth < 800 && <NavbarButton>
                <button type="button" onClick={() => value.toggleMenu()}>
                    <i className="fas fa-bars"></i>
                </button>
            </NavbarButton>}
            <NavbarLinks></NavbarLinks>
        </NavbarWrapper>
    )
}

export default Navbar

const NavbarWrapper = styled.div(props => ( props.isOpen ? {
    marginTop: "5px",
    display: "grid",
    gridGap: "3px",
    gridTemplateColumns: "10px repeat(2, 1fr)",
    gridTemplateRows: "40px repeat(3, 50px)",
    gridTemplateAreas: "'. logo button' 'tours tours tours' 'book book book' 'about about about'"
} :
{
    marginTop: "5px",
    display: "grid",
    gridGap: "3px",
    gridTemplateColumns: "10px repeat(2, 1fr)",
    gridTemplateRows: "40px",
    gridTemplateAreas: "'. logo button'" 
}))

const NavbarButton = styled.div`
    grid-area: button;

    button {
        display: block;
        position: relative;
        margin-left: auto;
        margin-right: 10px;
        background: transparent;
        border: none;

        i {
            color: var(--mainWhite);
            font-size: 32px;
        }
    }
`

const NavbarLogo = styled.div`
    grid-area: logo;
    font-size: 30px;

    p {
        margin-top: 4px;
    }
`
