export default function ValidateAuth(values) {
    let errors = {};

    if (!values.email) {
        errors.email = 'Verplicht';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Ongeldig email adres';
    }

    if (!values.number) {
        errors.number = 'Verplicht';
    } else if (
        !/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(values.number)
    ) {
        errors.number = 'Ongeldig contact'
    }

    if (!values.name) {
        errors.name = 'Verplicht';
    } else if (
        values.name.length < 2
    ) {
        errors.name = 'Te kort!'
    } else if (
        values.name.length > 100
    ) {
        errors.name = 'Te lang!'
    }

    if (!values.amount) {
        errors.amount = 'Verplicht';
    } else if (
        !/^[0-9\s]*$/.test(values.amount)
    ) {
        errors.amount = 'Ongeldig aantal'
    } else if (
        values.amount < 2
    ) {
        errors.amount = 'Minimum: 2'
    } else if (
        values.amount > 30
    ) {
        errors.amount = 'Maximum: 30'
    }

    return errors;
}
