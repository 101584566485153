import React, { useContext, useState, useEffect } from "react"
import { AppContext } from "../../context"
import styled from "styled-components"
import { Link, useLocation } from "react-router-dom"

const NavbarLinks = () => {
    const value = useContext(AppContext)
    let location = useLocation();
    let [pathname, setPathname] = useState("");
    useEffect(() => {
        setPathname(location.pathname)
    }, [location]);

    return (
        window.innerWidth < 800 ? 
        value.state.isOpen && <>
                <NavbarTours className="navbar-link" pathname={pathname}>
                    <Link to="/tours" onClick={() => value.toggleMenu()}>
                        <p>Wandel Keuzes</p>
                    </Link>
                </NavbarTours>
                <NavbarBook className="navbar-link" pathname={pathname}>
                    <Link to="/book" onClick={() => value.toggleMenu()}>
                        <p>Boek Een Toer</p>
                    </Link>
                </NavbarBook>
                <NavbarAbout className="navbar-link" pathname={pathname}>
                    <Link to="/about" onClick={() => value.toggleMenu()}>
                        <p>Over</p>
                    </Link>
                </NavbarAbout>
            </> :
            <>
                <NavbarTours className="navbar-link" pathname={pathname}>
                    <Link to="/tours" onClick={() => value.toggleMenu()}>
                        <p>Wandel Keuzes</p>
                    </Link>
                </NavbarTours>
                <NavbarBook className="navbar-link" pathname={pathname}>
                    <Link to="/book" onClick={() => value.toggleMenu()}>
                        <p>Boek Een Toer</p>
                    </Link>
                </NavbarBook>
                <NavbarAbout className="navbar-link" pathname={pathname}>
                    <Link to="/about" onClick={() => value.toggleMenu()}>
                        <p>Over</p>
                    </Link>
                </NavbarAbout>
            </>
    )
}

export default NavbarLinks

const NavbarTours = styled.div`
    grid-area: tours;
    text-align: center;
    font-weight: 800;
    border-bottom: 1px solid white;

    a {
        text-decoration: none;
        color: ${props => props.pathname === "/tours" ? "white !important" : "#A3A3A3 !important"};
    }

    p {
        font-size: 18px;
    }
`

const NavbarBook = styled.div`
    grid-area: book;
    text-align: center;
    font-weight: 800;
    border-bottom: 1px solid white;

    a {
        text-decoration: none;
        color: ${props => props.pathname === "/book" ? "white !important" : "#A3A3A3 !important"};
    }

    p {
        font-size: 18px;
    }
`

const NavbarAbout = styled.div`
    grid-area: about;
    text-align: center;
    font-weight: 800;
    border-bottom: 1px solid white;

    a {
        text-decoration: none;
        color: ${props => props.pathname === "/about" ? "white !important" : "#A3A3A3 !important"};
    }

    p {
        font-size: 18px;
    }
`
