import React from 'react'
import styled from "styled-components"

const Review = (props) => {
    return (
        <ReviewWrapper className="review">
            <Avatar>
                <img src={props.photoDesktop} alt="Person" width={window.innerWidth < 800 ? "60" : "120"}></img>
            </Avatar>
            <Comment className="review-comment">
                <i>“{props.comment}”</i>
            </Comment>
            <Name className="review-name">
                <p>- {props.name}</p>
            </Name>
        </ReviewWrapper>
    )
}

export default Review

const ReviewWrapper = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 70px 1fr;
    grid-template-rows: repeat(2, auto) 20px;
    grid-template-areas:
        "avatar comment"
        "avatar name"
        ". .";
`

const Avatar = styled.div`
    grid-area: avatar;

    img {
        border: 1px solid var(--mainWhite);
        border-radius: 100px;
    }
`

const Comment = styled.div`
    grid-area: comment;
    font-family: "EB Garamond";
    font-weight: bold;
`

const Name = styled.div`
    grid-area: name;

    p {
        margin-top: 1px;
    }
`
