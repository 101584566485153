import React, { useContext } from "react"
import { AppContext } from "../context"
import Review from "./Review"

const ReviewList = () => {
    const value = useContext(AppContext)

    return (
        value.state.reviewData.map(item => {
            return <Review key={item.id} photoMobile={item.photoMobile} photoDesktop={item.photoDesktop} name={item.name} comment={item.comment}></Review>
        })
    )
}

export default ReviewList
